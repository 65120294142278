<template>
  <div class="lf-info">
    <div class="lf-info__form">
      <!--      <b>Видео-визитка участника*</b>-->
      <!--      <ul class="lf-info__form&#45;&#45;visit">-->
      <!--        <li>-->
      <!--          <p>-->
      <!--            – Видео-визитка на тему: "Какой <span>вклад</span> ты готов внести в-->
      <!--            студенчество Татарстана (для студентов РТ) и что тебе-->
      <!--            <span>даёт студенчество</span> (для студентов РФ)?"-->
      <!--          </p>-->
      <!--        </li>-->
      <!--        <li>-->
      <!--          – длительность видеосюжета не должна превышать-->
      <!--          <span>1,5 минуты</span>;-->
      <!--        </li>-->
      <!--        <li>-->
      <!--          – минимальное качество разрешения – <span>HD 720p</span> (1280x720);-->
      <!--        </li>-->
      <!--        <li>-->
      <!--          – видеоролик прикрепляется отдельной ссылкой (ВКонтакте, Google drive,-->
      <!--          Яндекс диск, YouTube или похожий сервис);-->
      <!--        </li>-->
      <!--        <li>-->
      <!--          – Необходимо заранее-->
      <!--          <span>открыть доступ к видеоролику</span> на просмотр для внешних-->
      <!--          пользователей.-->
      <!--        </li>-->
      <!--      </ul>-->
      <!--      <Input-->
      <!--        placeholder="Добавьте ссылку на вашу видео-визитку vk.com или youtube.com"-->
      <!--        v-model="formDataStatement.video_card"-->
      <!--        :error="v$.formDataStatement.video_card.$errors.length !== 0"-->
      <!--        :disabled="isDisabled"-->
      <!--      />-->
      <b>Какое сообщество вы представляете?*</b>
      <TabsRadio
        v-model="formDataStatement.community_represent"
        :error="v$.formDataStatement.community_represent.$errors.length !== 0"
        :tab-data="
          communityRepresents.map((item) => {
            return { text: item.title, value: item.id };
          })
        "
        :disabled="isDisabled"
      />
      <b v-if="formDataStatement.community_represent === 99">
        Тип сообщества*
      </b>
      <TextAreaBlock
        v-if="formDataStatement.community_represent === 99"
        placeholder="Другое"
        :max-value="100"
        v-model="formDataStatement.community_represent_other"
        :error="
          v$.formDataStatement.community_represent_other.$errors.length !== 0
        "
        :disabled="isDisabled"
      />
      <b v-if="formDataStatement.community_represent !== 0">
        Название сообщества?*
      </b>
      <TextAreaBlock
        v-if="formDataStatement.community_represent !== 0"
        placeholder="Введите название сообщества в котором Вы состоите"
        :max-value="100"
        v-model="formDataStatement.community_represent_name"
        :error="
          v$.formDataStatement.community_represent_name.$errors.length !== 0
        "
        :disabled="isDisabled"
      />
      <b>Ваша позиция в этой организации?*</b>
      <TabsRadio
        v-model="formDataStatement.position_organization"
        :error="v$.formDataStatement.position_organization.$errors.length !== 0"
        :tab-data="
          positionsOrganisations.map((item) => {
            return { text: item.title, value: item.id };
          })
        "
        :disabled="isDisabled"
      />
      <b v-if="formDataStatement.position_organization === 99">
        Название позиции*
      </b>
      <TextAreaBlock
        v-if="formDataStatement.position_organization === 99"
        placeholder="Другое"
        :max-value="100"
        v-model="formDataStatement.position_organization_other"
        :error="
          v$.formDataStatement.position_organization_other.$errors.length !== 0
        "
        :disabled="isDisabled"
      />
      <b>
        Что для тебя значит студенчество?*
        <Icon
          type="Help"
          :data-tooltip="'Ответ на развёрнутый вопрос должен быть полным, содержать от 4 до 6 предложений.'"
        />
      </b>
      <TextAreaBlock
        placeholder="Студенчество - это…"
        :max-value="2000"
        v-model="formDataStatement.what_does_student_mean_you"
        :error="
          v$.formDataStatement.what_does_student_mean_you.$errors.length !== 0
        "
        :disabled="isDisabled"
      />
      <b
        >Твоя цель поездки на «ЛИГА ФОРУМ»?*
        <Icon
          type="Help"
          :data-tooltip="'Ответ на развёрнутый вопрос должен быть полным, содержать от 4 до 6 предложений.'"
      /></b>
      <TextAreaBlock
        placeholder="Я хочу поехать на «ЛИГА ФОРУМ», потому что..."
        :max-value="2000"
        v-model="formDataStatement.purpose_travel"
        :error="v$.formDataStatement.purpose_travel.$errors.length !== 0"
        :disabled="isDisabled"
      />
      <b
        >Твои главные достижения за последние 3 года?*
        <Icon
          type="Help"
          :data-tooltip="'Ответ на развёрнутый вопрос должен быть полным, содержать от 4 до 6 предложений.'"
      /></b>
      <TextAreaBlock
        placeholder="Достижение 1. Я..."
        :max-value="2000"
        v-model="formDataStatement.your_main_achievements"
        :error="
          v$.formDataStatement.your_main_achievements.$errors.length !== 0
        "
        :disabled="isDisabled"
      />
      <b>Особенности здоровья (необязательное поле)</b>
      <TextAreaBlock
        placeholder="Хронические заболевания, аллергия"
        :max-value="100"
        v-model="formDataUser.health_features"
        :error="v$.formDataUser.health_features.$errors.length !== 0"
        :disabled="isDisabled"
      />
      <b>Предпочтение в питании?*</b>
      <TabsRadio
        v-model="formDataUser.food_type"
        :error="v$.formDataUser.food_type.$errors.length !== 0"
        :tab-data="
          foodTypes.map((item) => {
            return { text: item.title, value: item.id };
          })
        "
        :disabled="isDisabled"
      />
      <b>Размер одежды?*</b>
      <TabsRadio
        v-model="formDataUser.clothing_size"
        :error="v$.formDataUser.clothing_size.$errors.length !== 0"
        :tab-data="
          clothingSizes.map((item) => {
            return { text: item.title, value: item.id };
          })
        "
        :disabled="isDisabled"
      />
      <b>Как вы узнали о форуме?*</b>
      <TabsRadio
        v-model="formDataStatement.hear_about_forum"
        :error="v$.formDataStatement.hear_about_forum.$errors.length !== 0"
        :tab-data="
          hearAboutInfo.map((item) => {
            return { text: item.title, value: item.id };
          })
        "
        :disabled="isDisabled"
      />
      <b v-if="formDataStatement.hear_about_forum === 99"> Другой вариант* </b>
      <TextAreaBlock
        v-if="formDataStatement.hear_about_forum === 99"
        placeholder="Другое"
        :max-value="100"
        v-model="formDataStatement.hear_about_forum_other"
        :error="
          v$.formDataStatement.hear_about_forum_other.$errors.length !== 0
        "
        :disabled="isDisabled"
      />
    </div>
    <div class="lf-info__btn">
      <Button @click="saveInfo" :loading="isBtnLoading" v-if="!isDisabled">
        Подать заявку
      </Button>
      <Button
        v-if="rollbackAllowed"
        @protected-click="openPopup"
        @split-click="openPopup"
        secondary
        :loading="isDeleteBtnLoading"
        split-icon="Help"
      >
        Отказ от участия
      </Button>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Blocks/Input.vue";
import TabsRadio from "@/components/Blocks/TabsRadio.vue";
import TextAreaBlock from "@/components/Blocks/TextAreaBlock.vue";
import Button from "@/components/Blocks/Button.vue";
import moment from "moment/moment";
import { mapGetters, mapMutations, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import validations from "@/enums/validations";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import { helpers, required } from "@vuelidate/validators";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import Icon from "@/components/Blocks/Icon.vue";

export default {
  name: "LigaForumInfo",
  components: { Icon, Button, TextAreaBlock, TabsRadio, Input },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      isBtnLoading: false,
      formDataStatement: {
        // video_card: "",
        community_represent: "",
        community_represent_other: "",
        community_represent_name: "",
        position_organization: "",
        position_organization_other: "",
        what_does_student_mean_you: "",
        purpose_travel: "",
        your_main_achievements: "",
        hear_about_forum: "",
        hear_about_forum_other: "",
      },
      formDataUser: { food_type: "", clothing_size: "", health_features: "" },
    };
  },
  validations() {
    validations.infoLFStatement.community_represent.required =
      this.formDataStatement.community_represent !== 0
        ? helpers.withMessage("Обязательно к заполнению", required)
        : "";
    validations.infoLFStatement.community_represent_name.required =
      this.formDataStatement.community_represent !== 0
        ? helpers.withMessage("Обязательно к заполнению", required)
        : "";
    validations.infoLFStatement.community_represent_other.required =
      this.formDataStatement.community_represent === 99
        ? helpers.withMessage("Обязательно к заполнению", required)
        : "";
    validations.infoLFStatement.position_organization_other.required =
      this.formDataStatement.position_organization === 99
        ? helpers.withMessage("Обязательно к заполнению", required)
        : "";
    validations.infoLFStatement.hear_about_forum_other.required =
      this.formDataStatement.hear_about_forum === 99
        ? helpers.withMessage("Обязательно к заполнению", required)
        : "";

    return {
      formDataUser: validations.infoLFUser,
      formDataStatement: validations.infoLFStatement,
    };
  },
  props: ["currentProject", "type"],
  computed: {
    fieldsInfo() {
      return trayValidationsTexts.infoLF;
    },
    isDisabled() {
      let boolean;
      if (Object.values(this.currentCategoryData).length > 0) {
        if (this.validPeriodForRollback) {
          if (this.currentCategoryData?.status?.key === 0) {
            boolean = false;
          } else boolean = this.currentCategoryData?.status?.key !== 35;
        } else if (this.validPeriodFor35Status) {
          boolean = this.currentCategoryData?.status?.key !== 35;
        } else return true;
      }
      return this.isOldApplication || boolean;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    selectWinnersPeriod() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "select_winners";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    validWinnersPeriod() {
      return moment().isBetween(
        moment(this.selectWinnersPeriod?.start_at),
        moment(this.selectWinnersPeriod?.end_at),
        "day",
        "[]"
      );
    },
    statuses() {
      return JSON.parse(
        JSON.stringify(
          this.storage?.systemInfo?.list_statuses[
            `${this.currentCategoryData?.info_project?.key}`
          ] ?? []
        )
      );
    },
    currentStatus() {
      return this.statementId
        ? "0"
        : Object.keys(this.statuses).find((item) => {
            return +item === this.currentCategoryData?.status?.key;
          });
    },
    rollbackAllowed() {
      let boolean;
      if (this.validPeriodForRollback) {
        if (
          this.currentProject?.id === 1 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (this.currentProject?.id === 4) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (this.currentProject?.id === 5) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50" ||
            this.currentStatus === "100";
        }
      } else if (this.validPeriodFor35Status) {
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 5 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50" ||
            this.currentStatus === "85" ||
            this.currentStatus === "100";
        }
      } else if (this.validWinnersPeriod) {
        if (this.currentProject?.id === 5) {
          boolean =
            this.currentStatus === "50" ||
            this.currentStatus === "85" ||
            this.currentStatus === "100";
        }
      } else boolean = false;

      return this.isOldApplication ? false : boolean;
    },
    ...mapState([
      "currentCategoryData",
      "statementId",
      "statementsList",
      "isOldApplication",
      "isDeleteBtnLoading",
      "storage",
      "firstShiftStatementData",
      "secondShiftStatementData",
      "thirdShiftStatementData",
    ]),
    ...mapState("user", ["disabledFields", "user"]),
    ...mapGetters([
      "communityRepresents",
      "positionsOrganisations",
      "foodTypes",
      "clothingSizes",
      "hearAboutInfo",
    ]),
  },
  methods: {
    async loadInfo() {
      const shiftData = `${this.type.toLowerCase()}ShiftStatementData`;
      const thisShiftData = this[shiftData];

      Object.keys(thisShiftData).forEach((item) => {
        if (thisShiftData[item] || thisShiftData[item] === 0) {
          if (`${item}` in this.formDataStatement)
            this.formDataStatement[item] = thisShiftData[item];
          if (`${item}` in this.formDataUser)
            this.formDataUser[item] = thisShiftData[item];
        }
      });
      if (this.currentCategoryData?.status?.key === 0) {
        Object.keys(this.formDataUser).forEach((item) => {
          if (this.user[item]) {
            this.formDataUser[item] = this.user[item];
          }
        });
      }
    },
    async setFakeUserData() {
      Object.keys(this.formDataUser).forEach((item) => {
        this.user[item] = this.formDataUser[item];
      });
    },
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupRevokeApplication",
        isShown: true,
        props: {
          id: this.statementId
            ? this.statementId
            : this.currentCategoryData?.id,
          fromProject: true,
        },
      });
    },
    async saveInfo() {
      if (this.isDateInvalid) return;

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }

      let payloadObjectInfo = {};
      Object.keys(this.formDataUser).map((item) => {
        if (this.formDataUser[item]) {
          return (payloadObjectInfo[item] = this.formDataUser[item]);
        }
      });
      this.isBtnLoading = true;
      createRequest(requestConfigs.POSTSaveInfo, {
        jsonPayload: payloadObjectInfo,
      })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
          this.setFakeUserData();
        })
        .then(async () => {
          this.isBtnLoading = true;
          this.setShiftStatementData({
            key: `${this.type.toLowerCase()}ShiftStatementData`,
            form: this.formDataStatement,
          });
          let payloadObjectStatement = {};
          const shiftData =
            this[`${this.type.toLowerCase()}ShiftStatementData`];
          Object.keys(shiftData).forEach((item) => {
            if (shiftData[item] || shiftData[item] === 0) {
              return (payloadObjectStatement[item] = shiftData[item]);
            }
          });
          await createRequest(requestConfigs.POSTSendStatement, {
            jsonPayload: payloadObjectStatement,
            routerPayload: {
              id: this.statementId || this.currentCategoryData.id,
            },
          })
            .then(() => {
              this.$store.commit("pushToTray", {
                text: "Заявка успешно подана",
                type: "success",
              });
            })
            .then(() => {
              this.$store.commit("setStatementId", "");
              this.$router.push({ name: "Applications" });
            })
            .catch((error) => {
              this.$store
                .commit("pushToTray", {
                  text: error?.errors[0]?.error_descr,
                  type: "error",
                })
                .finally(() => {
                  this.isBtnLoading = false;
                });
            });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error?.errors[0]?.error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    ...mapMutations(["setShiftStatementData", "clearShiftStatementData"]),
  },
  mounted() {
    this.loadInfo();
  },
};
</script>

<style lang="scss">
.lf-info {
  &__form {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    max-width: 728px;
    b {
      display: flex;
      align-items: center;
      grid-gap: 5px;
      i {
        position: relative;
      }
    }
    .input__label {
      font-weight: 700;
    }
    &--visit {
      display: flex;
      flex-direction: column;
      grid-gap: 5px;
      span {
        font-weight: 700;
      }
    }
  }
  .tabs-radio--error {
    color: var(--red);
    i {
      display: none;
    }
  }
  .tabs-radio__field {
    flex-direction: column;
  }
  &__btn {
    margin-top: 15px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    flex-wrap: wrap;
  }
  @include adaptive(tablet-small) {
    &__form {
      b {
        i {
          &[data-tooltip]::after {
            left: unset;
            top: 100%;
            right: calc(100% - 100px);
          }
        }
      }
    }
  }
}
</style>
